import React from 'react'
import { Link } from 'gatsby'

const BreadCrumb = ({data, title}) => {

    return(
        <ul class="BreadCrumb">
            {data && data.uid && <li><Link to={"/r/"+data.uid}>{data.document.data.title.text}</Link></li> }
            {data && data.uid && <li>/</li>}
           {data && data.uid && title && <li>{title}</li> }
        </ul>
    )
  
}

    


export default BreadCrumb;  