import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from '../components/PageTitle/PageTitle'
import BreadCrumb from '../components/BreadCrumb/BreadCrumb'
import ComponentSwitch from "../components/ComponentSwitch"
import { graphql } from 'gatsby'
import {
  TextBlockFragment,
  HeroImageAndTextFragment,
  FiftyFiftyFragment,
  ThreeIconFragment
} from '../fragments/prismicFragment'


const Page = props => {
  const data = props.data.prismicPage.data;

  return (
    <Layout location={props.location}>
      <SEO
        title={data.title && data.title.richText[0].text}
        description={data.meta_description && data.meta_description}
      />
      <div className="ContentPage">

        <div className="MainPageSection">


          <PageTitle PageName={data.title && data.title.text} />
          {data && data.parent_page && <BreadCrumb data={data.parent_page} title={data.title.text} /> }

          {data && data.body && <ComponentSwitch components={data.body} location={props.location} />}


        </div>

      </div>
    </Layout>
  )
}



export const query = graphql`
query PrismicPageData($uid: String) {
    prismicPage(uid: {eq:$uid}) {
      uid
      data{
        parent_page {
          link_type
          uid
          type
          target
          document {
            ... on PrismicPage {
              id
              data {
                title {
                  text
                }
              }
            }
          }
        }
          meta_description
          title{
            richText
            text
          }
            body{
            ... on PrismicPageDataBodyText {
              ...TextBlockFragment
            }

            ... on PrismicPageDataBodyHeroImageAndText{
              ...HeroImageAndTextFragment
            }

            ... on PrismicPageDataBodyFiftyFifty{
              ...FiftyFiftyFragment
            }

            ... on PrismicPageDataBodyThreeIcon{
              ...ThreeIconFragment
            }

          }
        }
      }
    }

`
export default Page

Page.fragments = [
  TextBlockFragment,
  HeroImageAndTextFragment,
  FiftyFiftyFragment,
  ThreeIconFragment
];